/* src/SuccessPage.css */
.success-page {
    text-align: center;
    padding: 20px;
    margin-top: 50px;
}

.success-page h2 {
    color: #4CAF50;
}

.success-page p {
    font-size: 1.2rem;
}

.dashboard-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-button:hover {
    background-color: #45a049;
}